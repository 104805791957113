@font-face {
  font-family: 'CircularStd';
  font-weight: 400;
  font-style: normal;
  src: local('CircularStd'), url('CircularStd-Book.otf') format('opentype');
}
@font-face {
  font-family: 'CircularStd';
  font-weight: 500;
  font-style: normal;
  src: local('CircularStd'), url('CircularStd-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'CircularStd';
  font-weight: 700;
  font-style: normal;
  src: local('CircularStd'), url('CircularStd-Bold.otf') format('opentype');
}
