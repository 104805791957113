@font-face {
  font-family: CircularStd;
  font-weight: 400;
  font-style: normal;
  src: local(CircularStd), url("CircularStd-Book.6f898156.otf") format("opentype");
}

@font-face {
  font-family: CircularStd;
  font-weight: 500;
  font-style: normal;
  src: local(CircularStd), url("CircularStd-Medium.a9d4bc0d.otf") format("opentype");
}

@font-face {
  font-family: CircularStd;
  font-weight: 700;
  font-style: normal;
  src: local(CircularStd), url("CircularStd-Bold.5e98f009.otf") format("opentype");
}
/*# sourceMappingURL=index.5c568f1e.css.map */
